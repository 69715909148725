import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../data/constant";
import { useAuthContext } from "../contexts/AuthContext";
import moment from "moment";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import Loader from "./Loader";
import { FaSearch } from 'react-icons/fa';

const Orders = () => {
  const { token } = useAuthContext();
  const { currentColor } = useStateContext();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const statusOptions = ["Processing", "Cancelled", "Delivered", "Shipped"];
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch orders
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const { data } = await axios.get(`${baseUrl}/order?isAdmin=true&search=${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setOrders(data.data);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [searchTerm]);

  // Handle status change
  const handleStatusChange = async (updatedOrder, newStatus) => {
    if (newStatus === "Shipped") {
      // Show the modal to input tracking number if the status is "Shipped"
      setSelectedOrder(updatedOrder);
      setShowTrackingModal(true);
      return;
    }

    try {
      const { data } = await axios.put(
        `${baseUrl}/order/${updatedOrder._id}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === updatedOrder._id
              ? { ...order, status: newStatus }
              : order
          )
        );
        toast.success("Status updated successfully!");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status.");
    }
  };

  // Handle submit tracking number
  const handleSubmitTracking = async () => {
    try {
      const { data } = await axios.put(
        `${baseUrl}/order/${selectedOrder._id}`,
        { status: "Shipped", tracking_number: trackingNumber },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === selectedOrder._id
              ? { ...order, status: "Shipped", tracking_number: trackingNumber }
              : order
          )
        );
        toast.success("Order shipped and tracking updated!");
        setShowTrackingModal(false);
        setTrackingNumber("");
        setSelectedOrder(null)
      }
    } catch (error) {
      console.error("Error updating tracking:", error);
      alert("Failed to update tracking.");
    }
  };

  return (
    <section className="text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Orders
          </h1>
          <div className="flex items-center border rounded w-full md:w-1/3 bg-white">
            <FaSearch className="text-gray-400 ml-3" />
            <input
              type="text"
              placeholder="Search by Order ID, Project ID, Product ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 text-md outline-none"
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-300">
            <table className="min-w-full table-auto bg-white border-collapse">
              <thead className="bg-gray-100 text-gray-700">
                <tr>
                  <th className="px-6 py-4 border-b text-center uppercase">Order ID</th>
                  <th className="px-6 py-4 border-b text-center uppercase">Project ID</th>
                  <th className="px-6 py-4 border-b text-center uppercase">Name</th>
                  <th className="px-6 py-4 border-b text-center uppercase">Shipping Date</th>
                  <th className="px-6 py-4 border-b text-center uppercase">Total Price</th>
                  <th className="px-6 py-4 border-b text-center uppercase">Status</th>
                  <th className="px-6 py-4 border-b text-center uppercase">Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders?.map((order) => (
                  <tr key={order._id}>
                    <td className="px-6 py-4 border-b text-center">{order.order_uuid}</td>
                    <td className="px-6 py-4 border-b text-center">{order.proposal.project.project_uuid}</td>
                    <td className="px-6 py-4 border-b text-center">{order.shipping_details.first_name} {order.shipping_details.last_name}</td>
                    <td className="px-6 py-4 border-b text-center">{moment(order.shipping_date).format("MM-DD-YYYY")}</td>
                    <td className="px-6 py-4 border-b text-center">${order.pricing_summary.total_price.toFixed(2)}</td>
                    <td className="px-6 py-4 border-b text-center">
                      <select
                        value={order.status}
                        onChange={(e) => handleStatusChange(order, e.target.value)}
                        className="border border-gray-300 rounded px-3 py-1"
                      >
                        {statusOptions.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      <button
                        onClick={() => {
                          const products = [];
                          order.proposal?.areas?.forEach((area) => {
                            area.products.forEach((product) => {
                              products.push({
                                name: product.name,
                                proId: product.proId,
                                quantity: product.quantity,
                                length: product.length,
                                unit_price: product.unit_price,
                                by_unit_or_length: product.by_unit_or_length,
                              });
                            });
                          });
                          setProducts(products);
                          setSelectedOrder(order);
                        }}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Dialog for order details */}
      {selectedOrder && !showTrackingModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full p-6">
            <h2 className="text-xl font-semibold mb-4">Order Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
              <div className="p-4 border border-gray-300 rounded">
                <p><strong>Order ID:</strong> {selectedOrder.order_uuid}</p>
                <p><strong>Order Status:</strong> {selectedOrder.status}</p>
                <p><strong>Shipping Date:</strong> {moment(selectedOrder.shipping_date).format("MM-DD-YYYY")}</p>
                <p><strong>Total Price:</strong> ${selectedOrder.pricing_summary.total_price.toFixed(2)}</p>
                <p><strong>Created At:</strong> {moment(selectedOrder.createdAt).format("MM-DD-YYYY")}</p>
              </div>
              <div className="p-4 border border-gray-300 rounded">
                <h3 className="text-lg font-semibold mb-2">Shipping Details</h3>
                <p><strong>Name:</strong> {selectedOrder.shipping_details.first_name} {selectedOrder.shipping_details.last_name}</p>
                <p><strong>Contact:</strong> {selectedOrder.shipping_details.contact_phone}</p>
                <p><strong>Address:</strong> {selectedOrder.shipping_details.address}, {selectedOrder.shipping_details.city}, {selectedOrder.shipping_details.province}, {selectedOrder.shipping_details.country} - {selectedOrder.shipping_details.zip_code}</p>
              </div>
              <div className="p-4 border border-gray-300 rounded">
                <h3 className="text-lg font-semibold mb-2">Billing Details</h3>
                <p><strong>Company:</strong> {selectedOrder.billing_details.company}</p>
                <p><strong>Email:</strong> {selectedOrder.billing_details.email}</p>
                <p><strong>Tax Type:</strong> {selectedOrder.tax_type}</p>
                <p><strong>Ship as product is available:</strong> {selectedOrder.ship_as_available ? 'Yes' : 'No'}</p>
              </div>
              <div className="p-4 border border-gray-300 rounded">
                <h3 className="text-lg font-semibold mb-2">Proposal Details</h3>
                <p><strong>Proposal Name:</strong> {selectedOrder.proposal.name}</p>
                <p><strong>Proposal ID:</strong> {selectedOrder.proposal.proposal_uuid}</p>
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <div className="w-full max-w-4xl">
                <h3 className="text-lg font-semibold mb-4 text-center">Products Details</h3>
                <div className="overflow-y-auto max-h-64">
                  <table className="min-w-full table-auto border-collapse text-center">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Product ID</th>
                        <th className="py-2 px-4 border-b">Length</th>
                        <th className="py-2 px-4 border-b">Quantity</th>
                        <th className="py-2 px-4 border-b">Unit price</th>
                        <th className="py-2 px-4 border-b">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products?.map((product, index) => (
                        <tr key={index} className="border-b">
                          <td className="py-2 px-4">{product.name}</td>
                          <td className="py-2 px-4">{product.proId}</td>
                          <td className="py-2 px-4">{product.length}</td>
                          <td className="py-2 px-4">{product.quantity}</td>
                          <td className="py-2 px-4">${product.unit_price}</td>
                          <td className="py-2 px-4">${product.by_unit_or_length == 'by_unit' ? (product.unit_price * product.quantity) : (product.unit_price * parseFloat(product.length))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setSelectedOrder(null)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Shipment Tracking Modal */}
      {showTrackingModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
            <h2 className="text-xl font-semibold mb-4">Enter Shipment Tracking Details</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Tracking Number</label>
              <input
                type="text"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setShowTrackingModal(false);
                  setSelectedOrder(null);
                  setTrackingNumber("");
                }}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 mr-4"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitTracking}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Orders;
